import React, { useState } from "react";
import axios from "axios";
import '../styles/faq.css';
import TagManager from 'react-gtm-module'
// import Accordion from '../../components/faq.js';


function PaymentForm(props) {

	const [formData, setFormData] = useState({
		name: '',
		last_name: '',
		email: '',
		ccNumber: '',
		expMonth: '',
		expYear: '',
		cardCode: '',
		city: '',
		zip: '',
		address: ''
	});

	const tagManagerArgs = {
		gtmId: 'GTM-P3PVRNQ',
		dataLayer: {
		  page: 'payment',
		  price: props.price,
		  event: 'payment' //payment
		}
	  }

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [result, setResult] = useState();
	const [alert, setAlert] = useState();
	const [isProcessing, setIsProcessing] = useState(false);


	const handleSubmit = (e) => {
		setIsProcessing(true);
		setTimeout(() => {
			setIsProcessing(false);
		  }, 3000);
		  TagManager.initialize(tagManagerArgs)
		e.preventDefault();
		//console.log(formData);
		axios.post('https://l6ccsiypgl.execute-api.us-east-1.amazonaws.com/beta?fName=' + formData.name + '&lName=' + formData.last_name + '&customerEmail=' + formData.email + '&ccNum=' + formData.ccNumber + '&exDate=' + formData.expYear + '-' + formData.expMonth + '&ccCode=' + formData.cardCode + '&subscriptionType=' + props.type + '&year=' + props.selectedPlan + '&city=' + formData.city + '&state=' + formData.state + '&zip=' + formData.zip + '&address=' + formData.address + '')
			.then(function (response) {
				console.log(response);
				setResult('Payment processed successfully');
				setAlert('message');
			})
			.catch(function (error) {
				console.log(error);
				setResult('Payment is not successful');
				setAlert('error_message');
			});


	};
	// const items = [
	// 	{
	// 	   title: "Does a device get installed in my tank?",
	// 	   content: "Yes, that is how you receive your personal water level. Installation takes 45 mins."
	// 	},
	// 	{
	// 		title: "Do I need WIFI?",
	// 		content: "Yes, the tank monitoring device works with WIFI in your home or business."
	// 	 },
	// 	 {
	// 		title: "Device maintenance schedule?",
	// 		content: "Quarterly maintenance is performed by H2Zero."
	// 	 },
	// 	 {
	// 		title: "Which phone models does the app work on?",
	// 		content: "Apple IOS & Android."
	// 	 }
	//   ]
	return (


		<div className="container">

			<div id="form" className="form-main">
				<div className="wrap-item">
					<div className='sup-title'>
						<p>THE LAST STEP </p>
					</div>

					<div className='sub-title'>
						<p>Complete Payment</p>
					</div>

					<p className='description'>{props.message}</p>
					{/* <Accordion items={items} />  */}

				</div>

				<div className='wrap-item form'>
					<form onSubmit={handleSubmit}>
						<div className='wrap'>
							<p>Your plan {props.price}</p>
						</div>

						<div className="aline">

							<div className='wrap'>
								<input
									type="text"
									name="name"
									className='field'
									placeholder="First Name"
									value={formData.name}
									onChange={handleChange} />
							</div>
							<div className='wrap' id="wrap1">
								<input
									type="text"
									className='field'
									name="last_name"
									placeholder="Last Name"
									value={formData.last_name}
									onChange={handleChange} />
							</div>
						</div>

						<div className='wrap'>
							<input
								type="text"
								name="address"
								className='field'
								placeholder="Address"
								value={formData.address}
								onChange={handleChange} />
						</div>

						<div className="aline">
							<div className='wrap' id="wrap1">
								<input
									type="text"
									name="city"
									className="add"
									placeholder="Parish"
									value={formData.city}
									onChange={handleChange} />
							</div>
							<div className='wrap'>
								<input
									type="text"
									name="zip"
									className="field"
									placeholder="Zip Code"
									value={formData.zip}
									onChange={handleChange} />
							</div>

						</div>

						<div className='wrap'>
							<input
								type="text"
								className='field'
								name="email"
								placeholder="Email"
								value={formData.email}
								onChange={handleChange} />
						</div>

						<div className='wrap'>
							<input
								type="text"
								name="ccNumber"
								className='field'
								placeholder="Card Number"
								value={formData.ccNumber}
								onChange={handleChange} />
						</div>
						<div className="card-option">
							<div className='wrap flex'>
								<input
									type="text"
									name="expMonth"
									className='field'
									placeholder="MM"
									value={formData.expMonth}
									onChange={handleChange} />
							</div>
							<div className='wrap flex'>
								<input
									type="text"
									name="expYear"
									className='field'
									placeholder="YYYY"
									value={formData.expYear}
									onChange={handleChange} />
							</div>
							<div className='wrap flex'>
								<input
									type="text"
									className='field'
									name="cardCode"
									placeholder="CVV"
									value={formData.cardCode}
									onChange={handleChange} />
							</div>
						</div>
						<div>
							<div className={alert}>{result}</div>
							<button type="submit" className="btn" disabled={isProcessing}>
		                     {isProcessing ? 'Processing Payment...' : 'Start Subscription'}
							 </button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}


export default PaymentForm;