import React, { useState } from "react";
import PaymentForm from "../paymentForm/paymentForm";
import '../styles/pricing.css';
import { Link } from 'react-scroll';
import TagManager from 'react-gtm-module'

const Tabs = () => {

  const tagManagerArgs = {
    gtmId: 'GTM-P3PVRNQ',
    dataLayer: {
      page: 'Pricing',
      event: 'Book Appointment' //book_appointment
    }
  }

  const [activeTab, setActiveTab] = useState("tab1");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState('');

  const handlePlanClick = (plan, price, type, message) => {
    setSelectedPlan(plan);
    setPrice(price);
    setType(type);
    setMessage(message);
  };

  //  Functions to handle Tab Switching
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };
    const FirstTab = () => (
      <div className="tabs-one">
              <div className='pricig_main_section'>

  <div className='shape-one'>
  </div>
  <div className='price_header'>
    <div className='price_inner'>
  <h2>Pricing Plans</h2>
  <p>We designed our pricing plans for added value over time.</p>
  </div>
  </div>
    <div className='container' id='price'>
    <div className='row price'>
    <div className='main'>
      <div className="tabs-two">
      <div className='wrap'>
        <ul className='item pricing_ul'>
          <li>Mobile App / Web App</li>
          <li>Multiple Users</li>
          <li>Weekly / Monthly History</li>
          <li>Free Installation</li>
          <li>1 Free Water Test</li>
          <li>Free Relocation</li>
        </ul>
        <div className='item pricing'>
        <h2 className="title">1 year contract</h2>
        <div className="item_price">$668<span>/ Year</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
        </ul>
        <div>

        </div>
        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("1", "$668", "annual","You have selected 1-year Subscription which is a one time payment of $668. If this is the plan you desire, kindly provide your payment details to proceed with your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$668/Year</p>

        </div>
        <div className='item pricing'>
        <h2 className="title">2 year contract</h2>
        <div className="item_price">$609<span>/ Year</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
        </ul>

        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("2", "$609", "annual", "You have chosen the 2-Year Subscription Plan, which offers a discount. Pay only $609 per year for a total of two years. If this is the plan you desire, kindly provide your payment details to proceed with your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$609/Year</p>

        </div>
        <div className='item pricing' style={{marginBottom: "35px"}}>
        <div className="recommended">BEST VALUE</div>
        <h2 className="title">3 year contract</h2>
        <div className="item_price">$548<span>/ Year</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
        </ul>
        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("3", "$548", "annual", "You have chosen our most popular plan, the 3-Year Subscription Plan, which offers a 26% discount. Pay only $548 per year for a total of three years. If this is the plan you desire, kindly provide your payment details to proceed with your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$548/Year</p>

        </div>
      </div>
      </div>
    </div>
  </div>
  </div>
  </div>
        </div>
    );

    const SecondTab = () => (
      <div className="tabs-two">
       <div className='pricig_main_section'>


  <div className='shape-one'>

  </div>
  <div className='price_header'>
    <div className='price_inner'>
  <h2>Pricing Plans</h2>
  <p>We designed our pricing plans for added value over time.</p>
  </div>
  </div>
    <div className='container' id='price'>
    <div className='row price'>
    <div className='main'>
      <div className='wrap'>
        <ul className='item pricing_ul'>
          <li>Mobile App / Web App</li>
          <li>Multiple Users</li>
          <li>Weekly / Monthly History</li>
          <li>Free Installation</li>
          <li>1 Free Water Test</li>
          <li>Free Relocation</li>
        </ul>

        <div className='item pricing'>
        <h2 className="title">1 year contract</h2>
        <div className="item_price">$54<span>/ Month</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
        </ul>
        <div>

        </div>
        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("1", "$54", "monthly", "You have selected 1-year Subscription Plan with monthly payments of $54 per month for 12 months. If this is correct, add your payment information to process your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$54/Month</p>
        </div>


        <div className='item pricing'>
        <h2 className="title">2 year contract</h2>
        <div className="item_price">$51<span>/ Month</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
          <li className="cross_list"></li>
        </ul>

        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("2", "$51", "monthly", "You have selected 2-year Subscription Plan with monthly payments of $51 per month for 24 months. If this is correct, add your payment information to process your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$51/Month</p>
        </div>

      
        <div className='item pricing' style={{marginBottom: "35px"}}>
        <div className="recommended">BEST VALUE</div>
        <h2 className="title">3 year contract</h2>
        <div className="item_price">$45<span>/ Month</span></div>
        <ul className="featureList">
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
          <li className="check_list"></li>
        </ul>
        <Link to="form" smooth={true} duration={1000} ><button type="button" className="buttonstyle" onClick={() => handlePlanClick("3", "$45", "monthly", "You have selected 3-year Subscription Plan with monthly payments of $45 per month for 36 months. If this is correct, add your payment information to process your selection.")}><span className="btn-text">Choose Plan</span></button></Link>
        <p className="trial">$45/Month</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>

      </div>
        );



  return (
    <div className="Tabs">


      <div className="outlet">
        {activeTab === "tab1" ? <FirstTab /> : <SecondTab />}
        <div className="tabs-navigation">
        <ul className="nav">
        <li
          className={activeTab === "tab1" ? "active" : ""}
          onClick={handleTab1}
        >
          Bill yearly
        </li>
        <li
          className={activeTab === "tab2" ? "active" : ""}
          onClick={handleTab2}
        >
          Bill monthly
        </li>
      </ul>
      </div>
      <div className=' pricing-footer'>
      <h2>Own a business? Let’s talk about our business pricing.</h2>
      { (
        <PaymentForm
          price={price}
          selectedPlan={selectedPlan}
          type={type}
          message={message}
        />
      )}

      </div>
      </div>

    </div>
  );
};

export default Tabs;