import React from 'react';
import './credit-card-form.css';

// import Header from './components/header.js';
import Footer from './components/footer.js';
// import Pricing from './components/pricing.js';
// import Form from './components/form/form.js';
// import Home from './components/home.js';
// import Testimonials from './components/testimonials.js';
// import Accordion from './components/faq.js';
import Tabs from "./components/TabComponent/tab.js";
// import Paymentform from "./components/paymentForm/paymentForm.js";
// import { BrowserRouter, Routes, Route,Outlet} from "react-router-dom";


const PaymentForm = () => {

  return (
    <div className="container-class">
    {/* <Header /> */}
    {/* <Home /> */}
      <Tabs />
      {/* <Pricing /> */}
      {/* <Paymentform /> */}
      {/* <Form /> */}
      {/* <Testimonials /> */}
      {/* <Accordion items={items} /> */}
      <Footer />
      </div>
  );
};

export default PaymentForm ;